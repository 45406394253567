import React from "react";
import axios from 'axios';
import ConfigConstants from "../config/Config";
import { useNavigate } from "react-router-dom";

const EmailVerification = () => {
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search)

    React.useEffect(() => {
        const userId = queryParameters.get("userId");
        const emailVerificationToken = queryParameters.get("evToken");
        console.log(userId, " ---- ", emailVerificationToken);
        verifiedEmail(userId, emailVerificationToken);
     }, [])

    const verifiedEmail = (userId, emailVerificationToken) => {
        axios.post(ConfigConstants.backendHost + '/verification/email', {
            userId: userId,
            token: emailVerificationToken
        })
        .then(res => {
            if(res.data.success) {
                navigate("/email-verification-success");
            } else {
                navigate("/email-verification-fail");
            }
        })
        .catch(error => { 
            navigate("/email-verification-fail");
        })
    }

    return(
        <div></div>
    );
}

export default EmailVerification;