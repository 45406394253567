import React from "react";
import PropTypes from "prop-types";

import "../../assets/reminder.css"

const ChannelHeaders = (props) => {
  return(
    <tr className="channel-header">
      <td className="channel-header-empty"></td>
      <td>*</td>
      <td>Type</td>
      <td>{props.channelReminder['repeatRule'] === (null || undefined) ? 'Remaining Retry' : 'Repeat Rule' }</td>
      <td>{props.channelReminder['repeatRule'] === (null || undefined) ? 'Retry Period' : 'Every Repeat Count' }</td>
      <td>{props.channelReminder['repeatRule'] === (null || undefined) ? 'Wait Minute'  : ''}</td>
      <td>Remaining Time to Next Trigger</td>
      <td></td> 
    </tr>
  );
};

ChannelHeaders.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

ChannelHeaders.defaultProps = {
  title: "Channel Headers"
};

export default ChannelHeaders;
