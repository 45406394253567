export default function() {
  return [
    /*{
      title: "Blog Dashboard",
      to: "/blog-overview",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    },
    {
      title: "Blog Posts",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/blog-posts",
    },
    {
      title: "Add New Post",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/add-new-post",
    },
    {
      title: "Forms & Components",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/components-overview",
    },
    {
      title: "Tables",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/tables",
    },
    {
      title: "User Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile-lite",
    },
    {
      title: "Errors",
      htmlBefore: '<i class="material-icons">error</i>',
      to: "/errors",
    },*/
    {
      title: "Dashboard",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/reminders-dashboard",
    },
    {
      title: "Create Reminder",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/create-reminder",
    },
    {
      title: "User Detail",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-detail",
    },
    {
      title: "Token Info",
      htmlBefore: '<i class="material-icons">info</i>',
      to: "/token-info",
    },
    {
      title: "Contact Us",
      htmlBefore: '<i class="material-icons">message</i>',
      to: "/contact-us",
    },
    {
      title: "",
      htmlBefore: '',
      to: "/",
    },
    {
      title: "Logout",
      htmlBefore: '<i class="material-icons text-danger">&#xE879;</i>',
      to: "/logout",
    }
  ];
}
