import React,{ useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader } from "shards-react";
import moment from"moment";
import axios from 'axios';
import ConfigConstants from "../../config/Config";
import ChannelHeaders from "./ChannelHeaders";
import ChannelReminder from "./ChannelReminder";
import Cookies from 'js-cookie';

const ReminderList = (props) => {

  const [showChannelRules, setShowChannelRules] = useState(false);
  const [channelRuleButtonName, setChannelRuleButtonName] = useState("Open Rules");

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const changeChannelRuleVisibility = () => {
    setShowChannelRules(!showChannelRules);
    setChannelRuleButtonName(channelRuleButtonName === 'Open Rules' ? 'Close Rules' : 'Open Rules');
  }

  const toggleShowModal = () => {
      setShowModal(!showModal);
  };

  const cancelReminder = (groupId) => {
    const headers = {
      'Authorization': 'Bearer ' + Cookies.get('jwtToken')
    }

    axios.get(ConfigConstants.backendHost + '/reminder/cancel?groupId=' + groupId + '&userId=' + Cookies.get('userId'), { headers: headers })
    .then(res => {
      if(!res.data.success) {
        setErrorMessage(res.data.message);
        setShowModal(true);
      }
    }) 
    .catch(error => { 
        if (error.response) {
            setErrorMessage(error.response.data);
            setShowModal(true);
        } else if (error.request) {
            setErrorMessage(error.request.responseJSON);
            setShowModal(true);
        } else {
            setErrorMessage(error.message);
            setShowModal(true);
        }
    })
  }

  return (
    <>
     {
      props.reminderList.map((reminder, remIndex) => (
        (
          reminder.rowType === 1 &&
          <tr>
            <td>{props.mapIndex}</td>
            <td>{reminder['name']}</td>
            <td>{moment(reminder['createdDate']).format('DD/MM/YYYY HH:mm:ss')}</td>
            <td>{reminder['cost']}</td>
            <td><Button onClick={ changeChannelRuleVisibility }>{channelRuleButtonName}</Button></td>
            {
              props.active && !reminder['existProcessed'] ? <td><Button onClick={ () => cancelReminder(reminder['groupId']) } style={{backgroundColor: "orange"}}>Cancel Reminder</Button></td> : 
              <td></td>
            }
            <td></td>
            <td></td>
          </tr>
        )
        ||
        (
          reminder.rowType === 2 && showChannelRules &&
          <ChannelHeaders channelReminder={reminder}/>
        )
        ||
        (
          reminder.rowType === 3 && showChannelRules &&
          <ChannelReminder index={(remIndex-1)} channelReminder={reminder}/>
        )
      ))
     }
      <Modal open={showModal} toggle={toggleShowModal}>
        <ModalHeader><i class='fas fa-exclamation-circle' style={{fontSize: '20px', color: 'red'}}></i> Error</ModalHeader>
        <ModalBody>{errorMessage}</ModalBody>
      </Modal>
    </>
  );
};

ReminderList.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

ReminderList.defaultProps = {
  title: "Reminder List"
};

export default ReminderList;
