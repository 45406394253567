import React from "react";
import { useNavigate } from "react-router-dom";
import { Checkmark } from 'react-checkmark'
import { Button } from "shards-react";

const EmailVerificationSuccess = () => {
    const navigate = useNavigate();

    const goToLogin = () => {
        navigate("/login");
    }

    return(
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', height: '300px'}}> 
            <Checkmark size='xxLarge' />
            <h1 style={{margin: '10px 0'}}>Email verification suceeded</h1>
            <Button theme="accent" onClick={ goToLogin }>Go to Login</Button>
        </div>
    );
}

export default EmailVerificationSuccess;