import React,{ useState }  from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import ConfigConstants from "../config/Config";
import Cookies from 'js-cookie';
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Modal, ModalBody, ModalHeader } from "shards-react";

import "../assets/register.css"

const Register = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        if(Cookies.get("email"))
            navigate("/user-detail");
     }, [])

    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [email, setEmail] = useState("");
    const [passVisible, setPassVisible] = useState(false);
    const [passAgainVisible, setPassAgainVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState();
    const [errorModal, setErrorModal] = useState(true);

    const togglePasswordVisible = () => {
        setPassVisible(!passVisible);
    };

    const togglePasswordAgainVisible = () => {
        setPassAgainVisible(!passAgainVisible);
    };

    const toggleShowModal = () => {
        if(showModal)
            setErrorModal(true);
        setShowModal(!showModal);
    };

    const doRegister = () => {

        if(password === passwordAgain) {
            axios.post(ConfigConstants.backendHost + '/user/register', {
                "password": password,
                "email": email
            })
            .then(res => {
                if(res.data.success) {
                    setErrorModal(false);
                    setModalMessage('User Created \n\n Please check your email for verification');
                    setShowModal(true);
                    navigate("/login");
                } else {
                    setModalMessage(res.data.message);
                    setShowModal(true);
                }
                
            }) 
            .catch(error => { 
                if (error.response) {
                    setModalMessage(error.response.data);
                    setShowModal(true);
                } else if (error.request) {
                    setModalMessage(error.request.responseJSON);
                    setShowModal(true);
                } else {
                    setModalMessage(error.message);
                    setShowModal(true);
                }
            })
        } else {
            setModalMessage("Passwords are mismatched");
            setShowModal(true);
        }

    }

    return(
        <div class="login-container">
            <div class="screen">
                <div class="screen__content">
                    <div class="register">
                        <div class="login__field">
                            <i class="login__icon fas fa-user"></i>
                            <input type="text" class="login__input" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                        </div>
                        <div class="login__field">
                            <i class="login__icon fas fa-lock"></i>
                            <input type={!passVisible ? "password" : "text"} class="login__input" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                            <span className="icon" onClick={togglePasswordVisible}>
                                {passVisible ? <EyeOutlined/> : <EyeInvisibleOutlined />}
                            </span>
                        </div>
                        <div class="login__field">
                            <i class="login__icon fas fa-lock"></i>
                            <input type={!passAgainVisible ? "password" : "text"} class="login__input" value={passwordAgain} onChange={(e) => setPasswordAgain(e.target.value)} placeholder="Password Again" />
                            <span className="icon" onClick={togglePasswordAgainVisible}>
                                {passAgainVisible ? <EyeOutlined/> : <EyeInvisibleOutlined />}
                            </span>
                        </div>
                        <button class="button login__submit" onClick={doRegister}>
                            <span class="button__text">Register</span>
                            <i class="button__icon fas fa-chevron-right"></i>
                        </button>				
                    </div>
                </div>
                <div class="screen__background">
                    <span class="screen__background__shape screen__background__shape4"></span>
                    <span class="screen__background__shape screen__background__shape3"></span>		
                    <span class="screen__background__shape screen__background__shape2"></span>
                    <span class="screen__background__shape screen__background__shape1"></span>
                </div>		
            </div>
            <Modal open={showModal} toggle={toggleShowModal}>
                {
                    errorModal && 
                    <ModalHeader><i class='fas fa-exclamation-circle' style={{fontSize: '20px', color: 'red'}}></i> Error</ModalHeader>
                }
                {
                    !errorModal && 
                    <ModalHeader><i class='fas fa-circle-info' style={{fontSize: '20px', color: 'green'}}></i> Info</ModalHeader>
                }
                <ModalBody>{modalMessage}</ModalBody>
            </Modal>
        </div>
    )
}

export default Register;