import React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

import "../assets/login.css"

const Logout = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        Cookies.remove('jwtToken');
        Cookies.remove('userId');
        Cookies.remove('email');
        Cookies.remove('phoneNumber');
        Cookies.remove('appToken');
        navigate("/login");       
     }, [])

    return(
        <div>
        </div>
    )
}

export default Logout;