import React from "react";
import { Row, Col, Card, CardHeader, CardBody } from "shards-react";

const ChannelRules = (props) => (
  <Row>
    <Col>
      <Card small className="mb-4 overflow-hidden">
        <CardHeader className="bg-dark">
          <h6 className="m-0 text-white">Channel Rules</h6>
        </CardHeader>
        <CardBody className="bg-dark p-0 pb-3">
          <table className="table table-dark mb-0">
            <thead className="thead-dark">
              <tr>
                <th scope="col" className="border-0">
                  #
                </th>
                <th scope="col" className="border-0">
                  Channel Type
                </th>
                <th scope="col" className="border-0">
                  Retry Count
                </th>
                <th scope="col" className="border-0">
                  Retry Period
                </th>
                <th scope="col" className="border-0">
                  Wait Time
                </th>
              </tr>
            </thead>
            <tbody>
              {
                props.channelList.map((channel, index) =>
                  <tr>
                    <td>{index+1}</td>
                    <td>{channel["channelType"]}</td>
                    <td>{channel["retryCount"]} retries</td>
                    <td>Every {channel["retryPeriod"]} minutes</td>
                    {
                      index === 0 ? <td>-</td> 
                                  : <td>Wait {channel["waitTime"]} minutes after previous {props.channelList[index-1]["channelType"]} rule</td>
                    }
                  </tr>
                )
              }
            </tbody>
          </table>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

export default ChannelRules;
