import React from "react";

const EmailVerificationFail = () => {
    return(
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', height: '300px'}}> 
            <i class='fas fa-exclamation-circle' style={{fontSize: '96px', color: 'red'}}></i>
            <h1 style={{margin: '10px 0'}}>Email verification failed</h1>
        </div>
    );
}

export default EmailVerificationFail;