import React,{ useState } from "react";
import axios from 'axios';
import Cookies from 'js-cookie';
import ConfigConstants from "../config/Config";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import PageTitle from "../components/common/PageTitle";

import {
  Container,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  FormTextarea,
  Modal, 
  ModalBody, 
  ModalHeader
} from "shards-react";

const ContactUs = () => {

  const [phoneNumber, setPhoneNumber] = useState();
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [formData, setFormData] = useState({
    userId: Cookies.get('userId'),
    name: "",
    surname: "",
    email: "",
    phone: "",
    message: ""
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toggleShowModal = () => {
      setShowModal(!showModal);
  };

  const sendMessage = () => {
    formData['phone'] = phoneNumber;

    const headers = {
      'Authorization': 'Bearer ' + Cookies.get('jwtToken')
    }

    axios.post(ConfigConstants.backendHost + '/user/sendMessage', formData, { headers: headers })
      .then(res => {
        const response = res.data;

        if(!response.success) {
          setErrorMessage(response.message);
          setShowModal(true);
        }        
      }) 
      .catch(error => { 
        if (error.response) {
            setErrorMessage(error.response.data);
            setShowModal(true);
        } else if (error.request) {
            setErrorMessage(error.request.responseJSON);
            setShowModal(true);
        } else {
            setErrorMessage(error.message);
            setShowModal(true);
        }
      })
  };
    
  return(
    <>
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Contact Us" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
            <Col>
              <Form>
                <Row form>
                  <Col md="4" className="form-group">
                    <label htmlFor="feName">First Name</label>
                    <FormInput
                      id="feName"
                      name="name"
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label htmlFor="feSurname">Last Name</label>
                    <FormInput
                      id="feSurname"
                      name="surname"
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label htmlFor="feSurname">Age</label>
                    <FormInput
                      id="feSurname"
                      name="age"
                      type="number"
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md="6" className="form-group">
                    <label htmlFor="feEmail">Email</label>
                    <FormInput
                      type="email"
                      id="feEmail"
                      name="email"
                      onChange={handleInputChange}
                      autoComplete="email"
                    />
                  </Col>
                  <Col md="6" className="form-group">
                    <label htmlFor="fePhone">Phone Number</label>
                    <PhoneInput
                      placeholder="Enter phone number"
                      onChange={setPhoneNumber}/>
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feMessage">Message</label>
                    <FormTextarea
                      id="feMessage"
                      name="message"
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
                <Button theme="accent" onClick={ sendMessage }>Send Message</Button>
              </Form>
            </Col>
          </Row>
          <br></br>
          <label>This app is a very new application. Therefore, we would like to read all kinds of suggestions, criticisms, recommendations and complaints with pleasure and make our system as perfect as possible. Please contact us with any comments you may have.</label>
      </Container>
      <Modal open={showModal} toggle={toggleShowModal}>
        <ModalHeader><i class='fas fa-exclamation-circle' style={{fontSize: '20px', color: 'red'}}></i> Error</ModalHeader>
        <ModalBody>{errorMessage}</ModalBody>
      </Modal>
    </>
  );
};

export default ContactUs;
